import { createRouter, createWebHistory } from 'vue-router';
import MainLayout from '../layout/MainLayout.vue';
import DashboardLayout from '../layout/DashboardLayout.vue';
import DashboardLayoutAdmin from '../layout/DashboardLayoutAdmin.vue';
//import AccessLayout from '../layout/AccessLayout.vue';
import getPageTitle from '@/utils/get-page-title.js'
import Home from '../pages/Home.vue';
import Dashboard from '../views/Dashboard/Dashboard';
import DashboardAdmin from '../views/Dashboard/DashboardAdmin';
import { getToken } from '../utils/auth';
import { getRole } from '../utils/userInfo'


//https://github.com/NovoManu/vue-smart-layouts/tree/vue3


const routes = [
  {
    meta: { title: 'Home' },
    path: '/home',
    name: 'Home',
    component: MainLayout,
    children: [{
      path: '/home',
      component: Home
    },

    {
      path: '/login2',
      name: 'Login2',
      component: () =>
        import( /* webpackChunkName: "about" */ '../components/Login2.vue')
    },


    {
      path: '/about',
      name: 'About',
      component: () =>
        import( /* webpackChunkName: "about" */ '../pages/About.vue')
    },

    {
      path: '/corporate',
      name: 'Corporate',
      component: () =>
        import( /* webpackChunkName: "about" */ '../pages/Corporate.vue')
    },

    {
      path: '/contact',
      name: 'Contact',
      component: () =>
        import( /* webpackChunkName: "about" */ '../pages/Contact.vue')
    },

    {
      path: '/pricing',
      name: 'Pricing',
      component: () =>
        import( /* webpackChunkName: "about" */ '../pages/Pricing.vue')
    },

    {
      path: '/faq',
      name: 'Faq',
      component: () =>
        import( /* webpackChunkName: "about" */ '../pages/Faq.vue')
    },
    {
      path: '/forbidden',
      name: 'PermissionError',
      component: () =>
        import('../pages/PermissionError.vue')
    },
    {
      path: '/passwordDimenticata',
      name: 'PasswordDimenticata',
      component: () =>
        import('../pages/PasswordDimenticata.vue')
    },
    {
      path: '/register',
      name: 'Register',
      component: () =>
        import('../pages/Register.vue')
    },


    ]

  },
  {
      meta: { title: 'DashboardAdmin', Authorized: true, roles: ['Admin','President'] },
    path: '/dashboardAdmin',
    name: 'DashboardAdmin',
    component: DashboardLayoutAdmin,
    children: [{
      path: '',
      component: DashboardAdmin,
        meta: { Authorized: true, roles: ['Admin', 'President'] },
    },
    {
      path: '/contabilitaAdmin',
      name: 'ContabilitaAdmin',
        meta: { Authorized: true, roles: ['Admin', 'President'] },
      component: () =>
        import( /* webpackChunkName: "about" */'../views/Contabilita/ContabilitaAdmin.vue')
    },
    {
      path: '/customerorderAdmin',
      name: 'CustomerOrderAdmin',
        meta: { Authorized: true, roles: ['Admin', 'President'] },
      component: () =>
        import( /* webpackChunkName: "about" */'../views/CustomerOrder/CustomerOrderAdmin.vue')
    },
    {
      path: '/provvigioniAdmin',
      name: 'ProvvigioniAdmin',
        meta: { Authorized: true, roles: ['Admin', 'President'] },
      component: () =>
        import( /* webpackChunkName: "about" */'../views/Provvigioni/ProvvigioniAdmin.vue')
    },
    {
      path: '/agentiAdmin',
      name: 'AgentiAdmin',
        meta: { Authorized: true, roles: ['Admin', 'President'] },
      component: () =>
        import( /* webpackChunkName: "about" */'../views/Agenti/AgentiAdmin.vue')
    },
    {
      path: '/fatturaAdmin',
      name: 'FatturaAdmin',
        meta: { Authorized: true, roles: ['Admin', 'President'] },
      component: () =>
        import( /* webpackChunkName: "about" */'../views/Fatturazione/FatturazioneAdmin.vue')
    },
  ]
  },

  {
    meta: { title: 'Dashboard', Authorized: true, roles: ['User'] },
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardLayout,
    children: [{
      path: '',
      component: Dashboard,
      meta: { Authorized: true, roles: ['User'] },
    },

    {
      path: '/link',
      name: 'Link',
      meta: { Authorized: true, roles: ['User'] },
      component: () =>
        import('../views/Link/Link.vue'),
    },
 /*   {
      path: '/fatturazione',
      name: 'Fatturazione',
      meta: { Authorized: true, roles: ['User'] },
      component: () =>
        import('../views/Fatturazione/FatturaUser.vue'),
    },*/

    {
      path: '/account',
      name: 'Account',
      meta: { Authorized: true, roles: ['User'] },
      component: () =>
        import( /* webpackChunkName: "about" */ '../views/Account/AccountBootstrap.vue'),
    },

    {
      path: '/provvigioni',
      name: 'Provvigioni',
      meta: { Authorized: true, roles: ['User'] },
      component: () =>
        import( /* webpackChunkName: "about" */ '../views/Provvigioni/Provvigioni.vue'),
    },

    {
      path: '/strutturavendita',
      name: 'StrutturaVendita',
      meta: { Authorized: true, roles: ['User'] },
      component: () =>
        import( /* webpackChunkName: "about" */'../views/StrutturaVendita/StrutturaVendita.vue')
    },

    {
      path: '/customerorder',
      name: 'CustomerOrder',
      meta: { Authorized: true, roles: ['User'] },
      component: () =>
        import( /* webpackChunkName: "about" */'../views/CustomerOrder/CustomerOrder.vue')
    },
    {
      path: '/cambioPassword',
      name: 'CambioPassword',
      meta: { Authorized: true, roles: ['User','Admin'] },
      component: () =>
        import('../views/CambioPassword/CambioPassword.vue')
    },
    {
      path: '/download',
      name: 'Download',
      meta: { Authorized: true, roles: ['User'] },
      component: () =>
        import('../views/Download/Download.vue')
    },
    {
      path: '/test',
      name: 'test',
      component: () =>
        import('../views/Test.vue')
    },

    ],
  },
  {
    meta: { title: 'Access' },
    path: '/',
    name: 'Access',
    component: MainLayout,
    children: [{
      path: '',
      component: () =>
        import( /* webpackChunkName: "demo" */ '../pages/Access.vue')
    },]

  },

]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  document.title = getPageTitle(to.meta.title)

  const authRequired = (to.meta.Authorized == true);
  const loggedIn = getToken()


  if (loggedIn) {
    if (authRequired) {


      const userRoles = getRole()
      const hasPermission = to.meta.roles.includes(userRoles)
      if (hasPermission) {
        next();
      } else {
        next('/forbidden')
      }
    } else {
      next();
    }

  } else {
    if (authRequired) {
      next('/')
    } else {
      next();
    }

  }
});

export default router

