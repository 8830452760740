import axios from 'axios';
import store from '@/store'
import { getToken } from '@/utils/auth'
import router from '@/router'
//import { Message } from 'element-plus'

const service = axios.create({
    baseURL: (process.env.VUE_APP_NODE_ENV == "development") ? 'http://localhost:5000/api/' : 'https://webapi.amanthy.com/api/',
})


service.interceptors.request.use(
    config => {
        config.headers['X-ApiKey4REST'] = 'd24a0a38-a2ea-4c28-ac48-a8cf766a6f5e'
        config.headers['Content-Type'] = 'application/json'

        if (store.getters.loggedIn == true) {

            config.headers['X-Token'] = getToken()
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)


service.interceptors.response.use(function(response) {
        return response
    },
    function(error) {
        if (error.response == undefined) {

            return Promise.reject(new Error("Il server sta impiegando troppo tempo a rispondere."));

        } else {

            if ((error.response.status === 401) || (error.response.status === 403)) {
                router.push({ path: '/' });
                return Promise.reject(new Error("La sessione è scaduta, è necessario rifare il login."));
            }

            if (error.response.status === 400) {
                return Promise.reject(error);
            }
            if (
                (error.response.status === 404) ||
                (error.response.status === 405) ||
                (error.response.status === 406) ||
                (error.response.status === 407) ||
                (error.response.status === 408) ||
                (error.response.status === 409) ||
                (error.response.status === 410)) {
                return Promise.reject(new Error(error.response.statusText));
            }

            if (error.response.status === 411) {
                return Promise.reject(error);
            }
            if (error.response.status === 412) {
                return Promise.reject(error);
            }
            if (error.response.status === 413) {
                return Promise.reject(error);
            }
            if (error.response.status === 414) {
                return Promise.reject(error);
            }
            if (error.response.status === 415) {
                return Promise.reject(error);
            }
            if (error.response.status === 416) {
                return Promise.reject(error);
            }
            if (error.response.status === 417) {
                return Promise.reject(error);
            }
            if (error.response.status === 418) {
                return Promise.reject(error);
            }
            if (error.response.status === 422) {
                return Promise.reject(error);
            }
            if (error.response.status === 425) {
                return Promise.reject(error);
            }
            if (error.response.status === 426) {
                return Promise.reject(error);
            }
            if (error.response.status === 428) {
                return Promise.reject(error);
            }
            if (error.response.status === 429) {
                return Promise.reject(error);
            }
            if (error.response.status === 431) {
                return Promise.reject(error);
            }
            if (error.response.status === 451) {
                return Promise.reject(error);
            }
            if (
                (error.response.status === 500) ||
                (error.response.status === 501) ||
                (error.response.status === 502) ||
                (error.response.status === 503) ||
                (error.response.status === 504) ||
                (error.response.status === 505) ||
                (error.response.status === 506) ||
                (error.response.status === 507) ||
                (error.response.status === 508) ||
                (error.response.status === 509) ||
                (error.response.status === 510) ||
                (error.response.status === 511)) {
                if ((error.response.data != undefined) && (error.response.data.detail != undefined)) {
                    return Promise.reject(new Error(error.response.data.detail));
                } else {
                    return Promise.reject(error);
                }
                //.then(function() {
                // not called
                //}, function(error) {            
                //console.error(error); // Stacktrace
                //});             
            }
        }


    })

export default service