<template>
  <div class="table-responsive">
    <table class="table table-striped text-center">
      <thead>
        <tr>
          <td class="fw-bold">Data Ordine</td>
          <td class="fw-bold">ID Ordine</td>
          <td class="fw-bold">Agente</td>
          <td class="fw-bold">Codice Agente</td>
          <td class="fw-bold">Totale</td>
          <td class="fw-bold">IVA</td>
          <td class="fw-bold">Coupon</td>
          <td class="fw-bold">Spese di Spedizione</td>
          <td class="fw-bold">Stato</td>
          <td class="fw-bold">Email Cliente</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in orders" :key="item.pkCustomerOrder">
          <td>{{ item.orderCreated }}</td>
          <td>{{ item.orderID }}</td>
          <td>{{ item.agente }}</td>
          <td>{{ item.agenteCodice }}</td>
          <td>{{ item.total }}</td>
          <td>{{ item.totalTax }}</td>
          <td>{{ item.discountTotal }}</td>
          <td>{{ item.shippingTotal }}</td>
          <td>{{ item.status }}</td>
          <td>{{ item.billingEmail }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from "moment";
import { FetchCustomerOrder } from "@/api/fetchData.js";
export default {
  data() {
    return {
      pageNumber: 1,
      pageSize: 10,
      orders: [],
       ordersSearch: {
        DataInizio: undefined,
        DataFine: undefined,
        PkAgenti: undefined,
        Ricerca: undefined,
        Status: undefined,
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = false;
      await FetchCustomerOrder(
        this.pageNumber,
        this.pageSize,
        this.ordersSearch.DataInizio,this.ordersSearch.DataFine,this.ordersSearch.PkAgenti,this.ordersSearch.Ricerca,this.ordersSearch.Status
      ).then((response) => {
        this.orders = this.initTable(response.data);
      });
      this.loading = false;
    },
    initTable(data) {
      data.forEach((element) => {
        element.orderCreated = moment(element.orderCreated).format(
          "DD/MM/YYYY, HH:mm:ss"
        );
        element.discountTotal =
          (element.discountTotal + element.discountTax).toFixed(2) + "€";

        element.shippingTotal =
          (element.shippingTotal + element.shippingTax).toFixed(2) + "€";
        element.total = element.total.toFixed(2) + "€";
        element.totalTax = element.totalTax.toFixed(2) + "€";
      });
      return data;
    },
  },
};
</script>

<style>
</style>