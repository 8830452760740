<template>
  <footer class="footer row bg-dark py-3 mt-auto">
    <div class="col-12">
      <div class="container">
        <div
          class="
            row
            align-items-center
            justify-content-between
            flex-column flex-sm-row
          "
        >
          <div class="col-auto">
            <div class="small m-0 text-white">
              Copyright &copy; Your Website 2021
            </div>
          </div>
          <div class="col-auto">
            <a class="link-light small" href="#!">Privacy</a>
            <span class="text-white mx-1">&middot;</span>
            <a class="link-light small" href="#!">Terms</a>
            <span class="text-white mx-1">&middot;</span>
            <a class="link-light small" href="#!">Contact</a>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- fixed-bottom  -->
</template>

<script>
export default {};
</script>

<style scoped>

</style>