<template>
  <div>
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :unique-opened="false"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        mode="vertical"
        :router="true"
      >
        <el-menu-item index="/dashboardAdmin"
          ><i class="el-icon-menu"></i>
          <template #title> Dashboard</template></el-menu-item
        >
        <el-menu-item index="/agentiAdmin">
          <i class="el-icon-user-solid"></i
          ><template #title>Agenti</template></el-menu-item
        >
        <el-menu-item index="/customerorderAdmin">
          <i class="el-icon-takeaway-box"></i
          ><template #title>Ordine Cliente</template></el-menu-item
        >
        <el-menu-item index="/provvigioniAdmin">
          <i class="el-icon-money"></i
          ><template #title>Provvigioni</template></el-menu-item
        >

        <el-menu-item index="/fatturaAdmin">
          <i class="el-icon-collection"></i
          ><template #title>Fatture</template></el-menu-item
        >
          <el-menu-item index="/contabilitaAdmin">
          <i class="el-icon-coin"></i
          ><template #title>Contabilità</template></el-menu-item
        >
        <!--fatturaAdmin
        <el-menu-item index="/account">
        <i class="el-icon-user-solid"></i><template #title>Account</template></el-menu-item>
        <el-menu-item index="/link">
        <i class="el-icon-link"></i><template #title>Account</template></el-menu-item>
       
        <el-menu-item index="/strutturavendita">
        <i class="el-icon-data-analysis"></i><template #title>Struttura Vendita</template></el-menu-item>
        <el-menu-item index="/provvigioni">
        <i class="el-icon-money"></i><template #title>Provvigioni</template>-->
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import variables from "@/styles/variables.scss";

export default {
  computed: {
    ...mapGetters(["sidebar"]),
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    variables() {
      return variables;
    },
    isCollapse() {
      return !this.sidebar.opened;
    },
  },
};
</script>
<style scoped>
.custom-theme .el-menu--collapse {
  width: 64px;
}

.custom-theme .el-menu--collapse > .el-menu-item [class^="el-icon-"],
.custom-theme
  .el-menu--collapse
  > .el-submenu
  > .el-submenu__title
  [class^="el-icon-"] {
  margin: 0;
  vertical-align: middle;
  width: 24px;
  text-align: center;
}

.custom-theme .el-menu--collapse > .el-menu-item .el-submenu__icon-arrow,
.custom-theme
  .el-menu--collapse
  > .el-submenu
  > .el-submenu__title
  .el-submenu__icon-arrow {
  display: none;
}

.custom-theme .el-menu--collapse > .el-menu-item span,
.custom-theme .el-menu--collapse > .el-submenu > .el-submenu__title span {
  height: 0;
  width: 0;
  overflow: hidden;
  visibility: hidden;
  display: inline-block;
}

.custom-theme .el-menu--collapse > .el-menu-item.is-active i {
  color: inherit;
}

.custom-theme .el-menu--collapse .el-menu .el-submenu {
  min-width: 200px;
}

.custom-theme .el-menu--collapse .el-submenu {
  position: relative;
}

.custom-theme .el-menu--collapse .el-submenu .el-menu {
  position: absolute;
  margin-left: 5px;
  top: 0;
  left: 100%;
  z-index: 10;
  border: 1px solid #dfe4ed;
  border-radius: 2px;
  -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.custom-theme
  .el-menu--collapse
  .el-submenu.is-opened
  > .el-submenu__title
  .el-submenu__icon-arrow {
  -webkit-transform: none;
  transform: none;
}
</style>