import Cookies from 'js-cookie'

export function setName(value){
    return Cookies.set('name', value)
}
export function getName(){
    return Cookies.get('name')
}
export function removeName(){
    return Cookies.remove('name')
}
export function setLastName(value){
    return Cookies.set('lastname', value)
}
export function getLastName(){
    return Cookies.get('lastname')
}
export function removeLastName(){
    return Cookies.remove('lastname')
}
export function setRole(value){
    return Cookies.set('role', value)
}
export function getRole(){
    return Cookies.get('role')
}
export function removeRole(){
    return Cookies.remove('role')
}