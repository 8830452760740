import axiosRequest from "../utils/axiosRequest";
import { getToken } from "../utils/auth";

export function UpdateCustomerOrder(id, data) {
    return axiosRequest({
        url: `CustomerOrder/${id}`,
        method: 'put',
        data: data,
        headers: { 'Authorization': getToken() }
    })

}

export function CreateCustomerOrder(data) {
    return axiosRequest({
        url: `CustomerOrder/Manual`,
        method: 'post',
        data: data,
        headers: { 'Authorization': getToken() }
    })
}

export function UpdateAddress(data) {
    return axiosRequest({
        url: `Agenti/Agente/Residenza/`,
        method: 'put',
        data: data,
        headers: { 'Authorization': getToken() }
    })

}

export function UpdateBankAccount(data) {
    return axiosRequest({
        url: `Agenti/Agente/ContoCorrente/`,
        method: 'put',
        data: data,
        headers: { 'Authorization': getToken() }
    })
}

export function UpdateTaxOptions(data) {
    return axiosRequest({
        url: `Agenti/Agente/DatiFiscali/`,
        method: 'put',
        data: data,
        headers: { 'Authorization': getToken() }
    })
}

export function UpdateContact(data, id) {
    return axiosRequest({
        url: `Agenti/Agente/Contatti/${id}`,
        method: 'put',
        data: data,
        headers: { 'Authorization': getToken() }
    })
}

export function CreateContact(data) {
    return axiosRequest({
        url: `Agenti/Agente/Contatti`,
        method: 'post',
        data: data,
        headers: { 'Authorization': getToken() }
    })
}

export function DeleteContact(id) {
    return axiosRequest({
        url: `Agenti/Agente/Contatti/${id}`,
        method: 'patch',
        headers: { 'Authorization': getToken() }
    })
}


export function DeleteOrder(id) {
    return axiosRequest({
        url: `CustomerOrder/${id}`,
        method: 'patch',
        headers: { 'Authorization': getToken() }
    })
}


export function RicalcolaProvvigioni(id) {
    return axiosRequest({
        url: `CustomerOrder/${id}/Ricalcola`,
        method: 'patch',
        headers: { 'Authorization': getToken() }
    })
}


export function UpdateAgent(data) {
    return axiosRequest({
        url: `Agenti/Agente`,
        method: 'put',
        data: data,
        headers: { 'Authorization': getToken() }
    })
}

export function PasswordForgot(email) {
    return axiosRequest({
        url: `Authenticate/Password/renew?email=${email}`,
        method: 'put',
    })
}

export function AttivaAgente(id, data) {
    return axiosRequest({
        url: `Agenti/Agente/${id}/Attivare`,
        method: 'post',
        headers: { 'Authorization': getToken() },
        data: data,
    })
}

export function RinnovaLicenza(id, data) {
    return axiosRequest({
        url: `Agenti/Agente/${id}/Rinnovare`,
        method: 'post',
        headers: { 'Authorization': getToken() },
        data: data,
    })
}


export function RequestContabilizzareProvvigione(id, DataMese) {
    return axiosRequest({
        url: `Provvigioni/Agente/${id}/Contabilizzare`,
        method: 'post',
        headers: { 'Authorization': getToken() },
        data:DataMese
    })
}

export function RequestFatturareProvvigione(id,DataMese) {
    return axiosRequest({
        url: `Provvigioni/Agente/${id}/Fatturare`,
        method: 'post',
        headers: { 'Authorization': getToken() },
        data:DataMese
    })
}


export function AdminModificaProvvigione(PkFattura,PKAgenteProvvigione,tipoProvvigione) {
    return axiosRequest({
        url: `Fattura/${PkFattura}/Provvigioni/${PKAgenteProvvigione}/`,
        method: 'post',
        headers: { 'Authorization': getToken() },
        data:tipoProvvigione
    })
}