const getters = {
    sidebar: state => state.app.sidebar,
    size: state => state.app.size,
    device: state => state.app.device,
    loggedIn: state=>state.user.initialState.status.loggedIn,
    register: state=>state.user.register,
    role: state=>state.user.user.role,
    //permission_routes: state => state.permission.routes,
    //  visitedViews: state => state.tagsView.visitedViews,
    // cachedViews: state => state.tagsView.cachedViews,
    //token: state => state.user.token,
    //avatar: state => state.user.avatar,
    //name: state => state.user.name,
    //introduction: state => state.user.introduction,
    //roles: state => state.user.roles,
    
    //errorLogs: state => state.errorLog.logs
}
export default getters