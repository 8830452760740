import { getToken } from "../utils/auth";
import axiosRequest from "../utils/axiosRequest";

const queryString = require('query-string');

class Request {

    PageNumber = 0;
    PageSize = 0;
    allRows = false;
    ControllerName = "";

    constructor(controllerName, pageNumber, pageSize, allRows) {
        this.ControllerName = controllerName;
        this.PageNumber = pageNumber;
        this.PageSize = pageSize;
        this.allRows = allRows;
    }

    MakeRequest(action, methodName, params, header) {

        let result = new Object;
        var qs = queryString.stringify(params, { skipNulls: true });

        result.method = action;
        result.url = `/${this.ControllerName}`;

        if ((methodName != undefined) && (methodName != null)) {
            result.url += `/${methodName}`;
        }

        if (qs.trim() != "") {
            result.url += `?${qs}`;
        } else {
            result.url += '/';
        }
        let requestHeader = new Object;

        for (const property in header) {
            requestHeader[property] = header[property];
        }

        result.headers = requestHeader;


        return result;

    }

    GET(methodName, params, useAuthorization) {

        params.allRows = this.allRows;
        if (!this.allRows) {
            params.pageNumber = this.PageNumber;
            params.pageSize = this.PageSize;

        } else {
            params.pageNumber = 0;
            params.pageSize = 0;
        }

        let header = new Object
        if (useAuthorization === true)
            header.Authorization = getToken();

        let request = this.MakeRequest("GET", methodName, params, header)

        return axiosRequest(request);

    }

}

export function AdminFetchProvvigioniContabilita(id, dataMese, pkAgenti, status) {
    let parameters = new Object;
    parameters.DataMese = dataMese;
    parameters.PkAgenti = pkAgenti;
    parameters.Status = status;

    var qs = queryString.stringify(parameters, { skipNulls: true });

    return axiosRequest({
        method: 'get',
        url: `/Contabilita/Agente/${id}?${qs}`,
        headers: { 'Authorization': getToken() }
    })
}

export function Read(id) {
    return axiosRequest({
        url: `CustomerOrder/${id}`,
        method: 'get',
        headers: { 'Authorization': getToken() },
    })
}
export function AdminFetchProvvigioni(pageNumber, pageSize, DataInizio, DataFine, PkAgenti, Ricerca, Status, Tipo, AllRows) {
    var qs = queryString.stringify({ "pageNumber": pageNumber, "pageSize": pageSize, "DataInizio": DataInizio, "DataFine": DataFine, "PkAgenti": PkAgenti, "IdOrdine": Ricerca, "Status": Status, "Tipo": Tipo, "AllRows": AllRows }, { skipNulls: true });
    return axiosRequest({
        method: 'get',
        url: `/Provvigioni/?` + qs,
        headers: { 'Authorization': getToken() }
    })
}
export function FetchProvvigioniOrdine(id) {

    return axiosRequest({
        method: 'get',
        url: `Agenti/Agente/Ordini/${id}/Provvigioni/`,
        headers: { 'Authorization': getToken() }
    })
}
export function AdminFetchProvvigioniExpandRow(id, DataMese, PkAgenti, Ricerca, Status, Tipo) {
    var qs = queryString.stringify({ "DataMese": DataMese, "PkAgenti": PkAgenti, "Ricerca": Ricerca, "Status": Status, "Tipo": Tipo }, { skipNulls: true });
    return axiosRequest({
        method: 'get',
        url: `/Provvigioni/Agente/${id}/?` + qs,
        headers: { 'Authorization': getToken() }
    })
}

export function FetchCustomerOrder(pageNumber, pageSize, DataInizio, DataFine, PkAgenti, Ricerca, Status) {
    var qs = queryString.stringify({ "pageNumber": pageNumber, "pageSize": pageSize, "DataInizio": DataInizio, "DataFine": DataFine, "PkAgenti": PkAgenti, "Ricerca": Ricerca, "Status": Status }, { skipNulls: true });

    return axiosRequest({
        method: 'get',
        url: `Agenti/Agente/Ordini/?` + qs,
        headers: { 'Authorization': getToken() },
    })
}

export function LogProvvigioni(id) {
    return axiosRequest({
        url: `CustomerOrder/${id}/Log`,
        method: 'get',
        headers: { 'Authorization': getToken() }
    })
}

export function AdminFetchCustomerOrder(pageNumber, pageSize, pkCustomerOrder, DataInizio, DataFine, PkAgenti, Ricerca, Status, AllRows) {
    var qs = queryString.stringify({ "pageNumber": pageNumber, "pageSize": pageSize, "pkCustomerOrder": pkCustomerOrder, "DataInizio": DataInizio, "DataFine": DataFine, "PkAgenti": PkAgenti, "Ricerca": Ricerca, "Status": Status, "AllRows": AllRows }, { skipNulls: true });

    return axiosRequest({
        method: 'get',
        url: `/CustomerOrder/?` + qs,
        headers: { 'Authorization': getToken() },
    })
}


export function AdminFetchCustomerOrderTotals(pageNumber, pageSize, DataInizio, DataFine, PkAgenti, Ricerca, Status) {
    var qs = queryString.stringify({ "pageNumber": pageNumber, "pageSize": pageSize, "DataInizio": DataInizio, "DataFine": DataFine, "PkAgenti": PkAgenti, "Ricerca": Ricerca, "Status": Status }, { skipNulls: true });

    return axiosRequest({
        method: 'get',
        url: `/CustomerOrder/Totali/?` + qs,
        headers: { 'Authorization': getToken() },
    })
}

export function FetchProvvigioni(PkConto, pageNumber, pageSize, DataMese, PkAgenti, IdOrdine, Status, Tipo) {
    var qs = queryString.stringify({ "DataMese": DataMese, "PkAgenti": PkAgenti, "IdOrdine": IdOrdine, "Status": Status, "Tipo": Tipo }, { skipNulls: true });
    return axiosRequest({
        method: 'get',
        url: `Agenti/Agente/Provvigioni/?PkConto=${PkConto}&PageNumber=${pageNumber}&PageSize=${pageSize}&` + qs,
        headers: { 'Authorization': getToken() }
    })
}

export function FetchAgent() {
    return axiosRequest({
        method: 'get',
        url: 'Agenti/Agente/',
        headers: { 'Authorization': getToken() }
    })
}

export function FetchAgentsSelect() {
    return axiosRequest({
        method: 'get',
        url: `DatiComuni/Agenti`,
        headers: { 'Authorization': getToken() },
    })
}
export function FetchQualificheSelect() {
    return axiosRequest({
        method: 'get',
        url: `DatiComuni/Qualifiche`,
        headers: { 'Authorization': getToken() },
    })
}

export function FetchAgents(pageNumber, pageSize, PkAgenti, FkQualifica, Ricerca, Licenza, Status, StrutturaCompleta) {

    var qs = queryString.stringify({ "pageNumber": pageNumber, "pageSize": pageSize, "PkAgenti": PkAgenti, "FkQualifica": FkQualifica, "Ricerca": Ricerca, "Licenza": Licenza, "Status": Status, "StrutturaCompleta": StrutturaCompleta }, { skipNulls: true });

    return axiosRequest({
        method: 'get',
        url: `Agenti/?` + qs,
        headers: { 'Authorization': getToken() },
    })
}

export function FetchAddress() {
    return axiosRequest({
        method: 'get',
        url: 'Agenti/Agente/Residenza',
        headers: { 'Authorization': getToken() }
    })
}

export function FetchBankAccount() {
    return axiosRequest({
        method: 'get',
        url: 'Agenti/Agente/ContoCorrente',
        headers: { 'Authorization': getToken() }
    })
}

export function FetchTaxOptions() {
    return axiosRequest({
        method: 'get',
        url: 'Agenti/Agente/DatiFiscali',
        headers: { 'Authorization': getToken() }
    })
}

export function FetchContacts() {
    return axiosRequest({
        method: 'get',
        url: 'Agenti/Agente/Contatti',
        headers: { 'Authorization': getToken() }
    })
}

export function FetchNations() {
    return axiosRequest({
        method: 'get',
        url: `DatiComuni/Nazioni/`,
        headers: { 'Authorization': getToken() }
    })
}

export function FetchRegioni() {
    return axiosRequest({
        method: 'get',
        url: `DatiComuni/Regioni/`,
        headers: { 'Authorization': getToken() }
    })
}

export function FetchProvincia(regione) {
    return axiosRequest({
        method: 'get',
        url: `DatiComuni/Regioni/${regione}/Province/`,
        headers: { 'Authorization': getToken() }
    })
}

export function FetchComuni(regione, provincia) {
    return axiosRequest({
        method: 'get',
        url: `DatiComuni/Regioni/${regione}/Province/${provincia}/Comuni/`,
        headers: { 'Authorization': getToken() }
    })
}

export function FetchTaxProfiles() {
    return axiosRequest({
        method: 'get',
        url: `DatiComuni/TipiProfiliFiscali`,
        headers: { 'Authorization': getToken() }
    })
}

export function FetchINPSTax() {
    return axiosRequest({
        method: 'get',
        url: `DatiComuni/TipiContributiINPS`,
        headers: { 'Authorization': getToken() }
    })
}

export function FetchWithholdingTax() {
    return axiosRequest({
        method: 'get',
        url: `DatiComuni/TipiRitenuteFiscali`,
        headers: { 'Authorization': getToken() }
    })
}

export function FetchConti() {
    return axiosRequest({
        method: 'get',
        url: `Agenti/Agente/Conti`,
        headers: { 'Authorization': getToken() }
    })
}

export function FetchTree() {
    return axiosRequest({
        method: 'get',
        timeout: 60 * 10 * 1000,
        url: `Agenti/Agente/StrutturaVendita`,
        headers: { 'Authorization': getToken() }
    })
}

export function FetchDashboard(id) {
    return axiosRequest({
        method: 'get',
        timeout: 60 * 10 * 1000,
        url: `Agenti/Agente/Dashboard/${id}`,
        headers: { 'Authorization': getToken() }
    })
}
export function FetchPianoCarriera(id) {
    return axiosRequest({
        method: 'get',
        timeout: 60 * 10 * 1000,
        url: `Agenti/Agente/Dashboard/PianoCarriera/${id}`,
        headers: { 'Authorization': getToken() }
    })
}

export function FetchBarChartOrders() {
    return axiosRequest({
        method: 'get',
        url: `Grafici/Ordini`, //?Gruppo=${gruppo}&Tipo=${tipo}&DataInizio=${dataInizio}&DataFine=${dataFine} gruppo, tipo, dataInizio, dataFine
        headers: { 'Authorization': getToken() }
    })
}

export function FetchBarChartProvvigioni() {
    return axiosRequest({
        method: 'get',
        url: `Grafici/Provvigioni`, //?Gruppo=${gruppo}&Tipo=${tipo}&DataInizio=${dataInizio}&DataFine=${dataFine} gruppo, tipo, dataInizio, dataFine
        headers: { 'Authorization': getToken() }
    })
}

export function FetchLicenzeAgenti(id) {
    return axiosRequest({
        method: 'get',
        url: `Agenti/Agente/${id}/Licenze`,
        headers: { 'Authorization': getToken() },
    })
}

export function FetchQualificheAgenti(id) {
    return axiosRequest({
        method: 'get',
        url: `Agenti/Agente/${id}/Qualifiche`,
        headers: { 'Authorization': getToken() },
    })
}

export function AdminFetchRegisterInfo(id) {
    return axiosRequest({
        method: 'get',
        url: `Register/${id}`,
        headers: { 'Authorization': getToken() },
    })
}

export function AdminFetchFatture(pageNumber, pageSize, DataInizio, DataFine, PkAgenti) {
    var qs = queryString.stringify({ "pageNumber": pageNumber, "pageSize": pageSize, "PkAgenti": PkAgenti, "DataInizio": DataInizio, "DataFine": DataFine }, { skipNulls: true });
    return axiosRequest({
        method: 'get',
        url: `Fatture/?` + qs,
        headers: { 'Authorization': getToken() },
    })
}

export function AdminFetchFatturaStampare(id) {
    return axiosRequest({
        method: 'get',
        url: `Fatture/Fattura/${id}/Stampare`,
        headers: { 'Authorization': getToken() },
    })
}

export function AdminFetchFatturaProvvigioniStampare(id) {
    return axiosRequest({
        method: 'get',
        url: `Fatture/Fattura/${id}/Provvigioni`,
        headers: { 'Authorization': getToken() },
    })
}

export class Contabilita extends Request {

    constructor(pageNumber, pageSize, allRows) {
        super('Contabilita', pageNumber, pageSize, allRows);
    }

    Search(DataMese, PkAgenti, Status) {

        let parameters = new Object;
        parameters.DataMese = DataMese;
        parameters.PkAgenti = PkAgenti;
        parameters.Status = Status;

        return super.GET(null, parameters, true);

    }

}
//var qs = queryString.stringify({ "pageNumber": pageNumber, "pageSize": pageSize, "pkCustomerOrder": pkCustomerOrder, "DataInizio": DataInizio, "DataFine": DataFine, "PkAgenti": PkAgenti, "Ricerca": Ricerca, "Status": Status, "AllRows": AllRows }, { skipNulls: true });

//return axiosRequest({
//    method: 'get',
//    url: `/CustomerOrder/?` + qs,
//    headers: { 'Authorization': getToken() },
//})

export class CustomerOrder extends Request {

    constructor(pageNumber, pageSize, allRows) {
        super('CustomerOrder', pageNumber, pageSize, allRows);
    }

    Search(pkCustomerOrder,dataInizio, dataFine, ricercaTesto, pkAgenti, stato) {

        let parameters = new Object;
        parameters.DataInizio = dataInizio;
        parameters.DataFine = dataFine;
        parameters.PkAgenti = pkAgenti;
        parameters.Status = stato;
        parameters.Ricerca = ricercaTesto;
        parameters.pkCustomerOrder = pkCustomerOrder;

        return super.GET(null, parameters, true);

    }

}

export class DatiComuni extends Request {

    constructor(pageNumber, pageSize, allRows) {
        super('DatiComuni', pageNumber, pageSize, allRows);
    }

    getAgenti() {

        return super.GET("Agenti", {}, true);

    }

}

//export function FetchAgentsSelect() {
//    return axiosRequest({
//        method: 'get',
//        url: `DatiComuni/Agenti`,
//        headers: { 'Authorization': getToken() },
//    })
//}

