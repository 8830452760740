import axiosRequest from "../utils/axiosRequest";
import { getToken } from "../utils/auth";

export function Login(credentials) {
    return axiosRequest({
        url: `Authenticate/login`,
        method: 'post',
        data: JSON.stringify(credentials),
        withCredentials: true
    })


}
export function ChangePassword(credentials) {

    return axiosRequest({
        url: `Authenticate/Password/Change`,
        method: 'put',
        data: JSON.stringify(credentials),
        withCredentials: true,
        headers: { 'Authorization': getToken() }
    })


}

export function Register(data){
    return axiosRequest({
        url:'Register',
        method: 'post',
        data: JSON.stringify(data),
    })
}

export function Logout() {

    return axiosRequest({
        url: `Authenticate/logout`,
        method: 'post',
        headers:{'Authorization': getToken()}
    })


}

export function AdminResetPassword(id, email) {

    return axiosRequest({
        url: `Agenti/Agente/${id}/ResetPassword`,
        method: 'post',
        withCredentials: true,
        headers: { 'Authorization': getToken()},
        data: email,
    
    })


}

export function AdminLoginAccount(id) {

    return axiosRequest({
        url: `Agenti/Agente/${id}/LoginAccount`,
        method: 'get',
        withCredentials: true,
        headers: { 'Authorization': getToken() }
    })


}