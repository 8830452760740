<template>
  <div class="navbar">
    <hamburger
      id="hamburger-container"
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />
    <div class="right-menu">
      <el-dropdown>
        <span class="el-dropdown-link">
          <i class="el-icon-user me-2"> </i>
          <span class="fw-bold me-1">
          {{ $store.state.user.user.name }}
          {{ $store.state.user.user.lastName }}
          </span>
          <i class="el-icon-arrow-down el-icon--right me-2"></i>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item > <i class="el-icon-key"></i>Cambia Password</el-dropdown-item>
            <el-dropdown-item><i class="el-icon-setting"></i> Settings</el-dropdown-item>
            <el-dropdown-item divided @click="logout">Logout</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Hamburger from "@/components/Hamburger";
import {Logout} from "@/api/login.js"

export default {
  components: {
    Hamburger,
  },
  computed: {
    ...mapGetters(["sidebar"]),
  },

  methods: {
    handleCommand(command) {
      this.$message("click on item " + command);
    },
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    async logout() {
      await Logout().then(()=>{
        this.$store.dispatch("user/logout").then(() => {
        this.$router.push("/");
      }) 
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-icon-user{
  font-size: 17px;
}
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 30px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;
    position: relative;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 25px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
