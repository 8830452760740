<template >
  <main class="flex-shrink-0 wrapper">
    <!-- Navigation-->
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <div class="container-fluid px-5">
        <router-link class="navbar-brand" to="/">Dashboard Amanthy</router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
           <!-- <li class="nav-item">
              <router-link :class="['nav-link', $route.path=='/' ? 'active' : '' ]" to="/">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link :class="['nav-link', $route.path=='/about' ? 'active' : '' ]" to="/about">About</router-link>
            </li>
            <li class="nav-item">
              <router-link :class="['nav-link', $route.path=='/corporate' ? 'active' : '' ]" to="/corporate"
                >Azienda</router-link
              >
            </li>
            <li class="nav-item">
              <router-link :class="['nav-link', $route.path=='/contact' ? 'active' : '' ]" to="/contact">Contatti</router-link>
            </li>
            <li class="nav-item">
              <router-link :class="['nav-link', $route.path=='/pricing' ? 'active' : '' ]" to="/pricing">Prezzi</router-link>
            </li>
            <li class="nav-item">
              <router-link :class="['nav-link', $route.path=='/faq' ? 'active' : '' ]" to="/faq">FAQ</router-link>
            </li>-->
            <li class="nav-item">
              <router-link :class="['nav-link', $route.path=='/' ? 'active' : '' ]" to="/">Accedi</router-link>
            </li>
            <!--
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" id="navbarDropdownBlog" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">Accedi</a>
                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownBlog">
                                    <li><a class="dropdown-item" href="/Access">Login</a></li>
                                    <li><a class="dropdown-item" href="/Access">Registrati</a></li>
                                </ul>
                            </li>-->
          </ul>
        </div>
      </div>
    </nav>
  
  
  <router-view /></main>
  <div class="push"></div>
<Footer />

 
</template>

<script>
import Footer from "../components/MainLayout/Footer.vue"
export default {
  components:{Footer},
  data() {
    return {
      activeIndex: "1",
    };
  },

  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath); //?
    },
  },
};
</script>
<style scoped lang="css">
.wrapper {
  min-height: 100%;

  margin-bottom: -50px;
}

.push {
  height: 50px;
  margin-top: 10px;
}
@import url("../assets/css/main.css");
</style>
