import { createApp } from 'vue'

import 'normalize.css/normalize.css' // a modern alternative to CSS resets

import installElementPlus from './plugins/element'
import './styles/element-variables.scss'

import '@/styles/index.scss' // global css

import App from './App.vue'
import store from './store'
import router from './router'


import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.css"

import VueClipboard from 'vue3-clipboard'
import 'vue-orgchart/dist/style.min.css'

import Vue3SimpleHtml2pdf from "vue3-simple-html2pdf";

const app = createApp(App)
installElementPlus(app);
app.use(router);
app.use(store);
app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
});
app.use(Vue3SimpleHtml2pdf)
app.mount('#app')
