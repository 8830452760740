<script>
import { Bar } from "vue3-chart-v2";
export default {
  extends: Bar,
  props:{
    chartData: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
        delayed:false,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
            
          onComplete: () => {
            this.delayed = true;
          },
          delay: (context) => {
            let delay = 0;
            if (
              context.type === "data" &&
              context.mode === "default" &&
              !this.delayed
            ) {
              delay = context.dataIndex * 300 + context.datasetIndex * 100;
            }
            return delay;
          },
        },
      },
    };
  },

  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>