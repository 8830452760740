<template>
  <router-view></router-view>
</template>

<script>
import Cookies from "js-cookie";
export default {
  name: "App",
  components: {},

  watch: {
    $route() {
      if (this.$route.query.ref) {
        if (this.$route.query.ref != "0" && this.$route.query.ref != "") {
          this.SaveReferralID();
        }
      }
    },
  },
  methods: {
    SaveReferralID() {
      Cookies.set("referral", this.$route.query.ref);
    },
  },
};
</script>

<style>
</style>
