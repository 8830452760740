<template>
  <div class="row mt-5 mb-5 text-center">
    <h2>
      <el-button
        type="primary"
        size="large"
        @click="redirectToShop"
        :loading="loadingRefButton"
        ><font-awesome-icon :icon="cart" class="me-1"></font-awesome-icon
        >Acquista con il tuo Ref
      </el-button>
    </h2>
  </div>

  <div class="container-fluid">
    <div class="mb-5">
      <Summary />
    </div>
    <div class="row mt-5 mb-3">
      <div class="row mb-3 text-center">
        <div class="col">
          <h4>Grafico Provvigioni</h4>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col">
        <BarChart
          v-if="chartProvvigioni.isLoaded"
          :chartData="chartProvvigioni.chartData"
        />
      </div>
    </div>
    <div class="row mb-3 text-center mt-5">
      <div class="col">
        <h4>Ultimi 10 ordini</h4>
      </div>
    </div>
    <div class="row " v-if="device != 'mobile'">
      <div class="col">
        <el-table
          v-loading="loading"
          :data="orders"
          stripe
          fit
          highlight-current-row
        >
          <el-table-column
            label="Data Ordine"
            prop="orderCreated"
            align="center"
          />
          <el-table-column label="ID Ordine" prop="orderID" align="center" />
          <el-table-column label="Agente" prop="agente" align="center" />
          <el-table-column
            label="ID Agente"
            prop="agenteCodice"
            align="center"
          />
          <el-table-column label="Totale" prop="total" align="center" />
          <el-table-column label="IVA" prop="totalTax" align="center" />
          <el-table-column label="Coupon" prop="discountTotal" align="center" />
          <el-table-column
            label="Spese di Spedizione"
            prop="shippingTotal"
            align="center"
          />
          <el-table-column label="Stato" prop="status" align="center" />
          <el-table-column
            label="Email Cliente"
            prop="billingEmail"
            align="center"
          />
          <el-table-column
            label="Tipo Vendita"
            prop="tipoOrdine"
            align="center"
          />
        </el-table>
      </div>
    </div>
    <div class="mt-5" v-else>
      <Table />
    </div>
  <div class="row mt-3 mb-2 text-center">
    <div class="col">
      <el-button @click="goToOrders" type="primary">Visualizza tutti gli ordini</el-button>
    </div>
    
  </div>
  

  </div>
</template>

<script>
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import moment from "moment";
import { mapState } from "vuex";
import {
  FetchBarChartProvvigioni,
  FetchCustomerOrder,
  FetchConti,
} from "@/api/fetchData.js";
import BarChart from "../../components/Dashboard/Graph/BarChart.vue";
import Summary from "../../components/Dashboard/SummaryResponsive.vue";
import Table from "../../components/Dashboard/TableLastTenOrders.vue";
export default {
  components: { Table, Summary, BarChart, FontAwesomeIcon }, //
  created() {
    this.loadBarChartProvvigioni();
    this.loadData();
    this.loadConti();
  },
  computed: {
    ...mapState({
      device: (state) => state.app.device,
    }),
  },
  data() {
    return {
      loadingRefButton: false,
      cart: faShoppingCart,
      conti: null,
      dateOrders: null,
      dateProvvigioni: null,
      loadingOrdersSearch: false,
      loadingProvvigioniSearch: false,
      loading: false,
      pageNumber: 1,
      pageSize: 10,
      orders: [],
      order: [],
      chartOrders: {
        isLoaded: false,
        chartData: null,
        gruppo: "",
        tipo: "",
        dataInizio: "",
        dataFine: "",
      },
      chartProvvigioni: {
        isLoaded: false,
        chartData: null,
        gruppo: "",
        tipo: "",
        dataInizio: "",
        dataFine: "",
      },
      ordersSearch: {
        DataInizio: undefined,
        DataFine: undefined,
        PkAgenti: undefined,
        Ricerca: undefined,
        Status: undefined,
      },
      gruppo: [
        {
          value: 1,
          label: "Giorno",
        },
        {
          value: 2,
          label: "Mese",
        },
        {
          value: 3,
          label: "Trimestre",
        },
      ],
      tipo: [
        {
          value: 1,
          label: "Diretto",
        },
        {
          value: 2,
          label: "Team",
        },
        {
          value: 3,
          label: "Totale",
        },
        {
          value: 4,
          label: "Gambe",
        },
        {
          value: 5,
          label: "Agenti",
        },
      ],
    };
  },

  methods: {
    goToOrders(){
      this.$router.push({name:"CustomerOrder"})
    },
    async loadConti() {
      this.loadingRefButton = true;
      await FetchConti()
        .then((response) => {
          this.conti = response.data;
          this.url = response.data[0].url + "?ref=" + response.data[0].codice;
        })
        .catch(() => {
          this.fetchReferralError = true;
        });
      this.loadingRefButton = false;
    },
    redirectToShop() {
      window.location.href = this.url;
    },
    initTable(data) {
      data.forEach((element) => {
        element.orderCreated = moment(element.orderCreated).format(
          "DD/MM/YYYY, HH:mm:ss"
        );
        element.discountTotal =
          (element.discountTotal + element.discountTax).toFixed(2) + "€";

        element.shippingTotal =
          (element.shippingTotal + element.shippingTax).toFixed(2) + "€";
        element.total = element.total.toFixed(2) + "€";
        element.totalTax = element.totalTax.toFixed(2) + "€";
      });
      return data;
    },
    async loadData() {
      this.loading = false;
      await FetchCustomerOrder(
        this.pageNumber,
        this.pageSize,
        this.ordersSearch.DataInizio,
        this.ordersSearch.DataFine,
        this.ordersSearch.PkAgenti,
        this.ordersSearch.Ricerca,
        this.ordersSearch.Status
      ).then((response) => {
        this.orders = this.initTable(response.data);
      });
      this.loading = false;
    },
    async loadBarChartProvvigioni() {
      this.chartProvvigioni.isLoaded = false;
      await FetchBarChartProvvigioni(
        this.chartProvvigioni.gruppo,
        this.chartProvvigioni.tipo,
        this.chartProvvigioni.dataInizio,
        this.chartProvvigioni.dataFine
      ).then((response) => {
        this.chartProvvigioni.chartData = response.data;
        this.chartProvvigioni.isLoaded = true;
      });
    },
  },
};
</script>

<style>
</style>