import { setToken, getToken, removeToken } from "../../utils/auth";
import { setName, getName, removeName, setLastName, getLastName, removeLastName, setRole, getRole, removeRole } from "../../utils/userInfo";



const token = getToken()
const initialState = token
  ? { status: { loggedIn: true }, token }
  : { status: { loggedIn: false }, token: null };



const state = {
  initialState,
  user: {
    name: getName(),
    lastName: getLastName(),
    role: getRole(),
  }
}
const mutations = {
  loginSuccess(state, data) {
    state.initialState.status.loggedIn = true;
    state.initialState.token = token;
    state.user.name = data.user.name
    state.user.lastName = data.user.lastName
    state.user.role = data.user.role

  },
  loginFailure(state) {
    state.initialState.status.loggedIn = false;
    state.initialState.token = null;
  },
  logout(state) {
    state.initialState.status.loggedIn = false;
    state.initialState.token = null;

  },
  registerSuccess(state) {
    state.register = true;
  },
  registerFailure(state) {
    state.initialState.status.loggedIn = false;
  }
}
const actions = {
  login({ commit }, data) {
    setToken(data.token)
    setName(data.user.name)
    setLastName(data.user.lastName)
    setRole(data.user.role)
    commit('loginSuccess', data);
    return Promise.resolve();
  },

  register({ commit }) {
    commit('registerSuccess')
    return Promise.resolve();
  },



  logout({ commit }) {
    removeToken()
    removeName()
    removeLastName()
    removeRole()
    commit('logout');
    return Promise.resolve();
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}